import * as React from 'react'
import { useLanguage } from '../hooks/hooks'
import GlobalLayout from '../layouts/GlobalLayout'
import styled from 'styled-components'
import colors from '../layouts/common/colors'

const Popup = styled.div`
  box-sizing: border-box;
  width: 600px;
  height: 400px;
  display: flex;
  flex-direction: column;
  padding: 1em;
`

const Title = styled.h1`
  color: ${colors.blue};
  margin: 0 1em 1em 1em;
  text-align: center;
`

const Content = styled.div`
  margin: 1em;
  text-align: center;
  flex-grow: 1;
`

const ButtonContainer = styled.div`
  text-align: center;
  padding: 0.75em 2em;
`

const Button = styled.a`
  padding: 0.75em 2em;
  background-color: ${colors.blue};
  color: ${colors.white};
  border-radius: 0.5em;
`

const Notification : React.FunctionComponent = () => {
  const language = useLanguage()
  const [data, setData] = React.useState<any>(null)

  React.useEffect(() => {
    !data && fetch(`/notification/notification.json`)
      .then(response => response.json())
      .then(data => setData(data))
  }, [data, language])

  data && console.log(data)

  return data ? (
    <GlobalLayout>
      <Popup>
        <Title>{ data.title[language] || data.title['en'] }</Title>
        <Content dangerouslySetInnerHTML={{__html: data.content[language] || data.content.en }} />
        <ButtonContainer>
          <Button>{ language == 'nl' ? 'Lees meer' : 'Read more' }</Button>
        </ButtonContainer>
      </Popup>
    </GlobalLayout>
  ) : <></>
}

export default Notification
